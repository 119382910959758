const { href } = window.location
const links = document.querySelectorAll("nav a")

links.forEach((link) => {
  link.classList.remove("wavy")

  if (link.href === href) {
    link.classList.add("wavy")
  }
})

if (href.indexOf(".html") < 0) {
  links[0].classList.add("wavy")
}

window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('serviceworker.js').catch(console.log)
  }
})
